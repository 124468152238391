import Spacer from '@uilib/core/Spacer/Spacer';
import Tooltip from 'uilib-wrappers/tooltip';
import IconSymbolHelp from '@uilib/assets-business-icons/IconSymbolHelp';

function InfoTooltip(props) {
    return (
        <Spacer type="ml-auto">
            <Spacer type="ml-2">
                <Tooltip placement={props.placement} tooltipContent={props.tooltipContent}>
                    <IconSymbolHelp fill={props.iconColor} />
                </Tooltip>
            </Spacer>
        </Spacer>
    );
}

export default InfoTooltip;
