import React, { useMemo } from 'react';
import CombinedFilter from '@uilib/business-components/CombinedFilter/CombinedFilter';
import i18n from 'Services/i18n';
import colors from 'eset-ui-colors';

const ACTIVE = {
    TRUE: true,
    /**
     * It is tricky:
     * Selecting all elements from a grouped buttons filter should:
     *  - give the impression of visually selecting everything
     *  - ignoring everything from this filter in the background
     * that's why I introduced the active FAKE_TRUE type
     */
    FAKE_TRUE: 0,
    FALSE: false,
};

function CustomCombinedFilter(props) {
    const value = useMemo(
        () =>
            props.buttons?.reduce((pv, cv) => {
                return { ...pv, [cv.id]: !!cv.active || cv.active === ACTIVE.FAKE_TRUE };
            }, {}),
        [props.buttons]
    );

    const combinedFilterItems = useMemo(
        () =>
            props.buttons?.map((filter) => {
                const Icon = filter.icon;
                return {
                    content: Icon && React.isValidElement(<Icon />) && (
                        <Icon
                            fill={
                                colors[
                                    ((!!filter.active || filter.active === ACTIVE.FAKE_TRUE) && filter.color) ||
                                        'iconColor'
                                ]
                            }
                        />
                    ),
                    itemKey: filter.id,
                };
            }),
        [props.buttons]
    );

    const combinedFilterTitles = useMemo(
        () =>
            props.buttons?.reduce((accumulator, filter) => {
                if (filter.tooltipText) {
                    accumulator[filter.id] = i18n(filter.tooltipText);
                }
                return accumulator;
            }, {}),
        [props.buttons]
    );

    function handleCombinedFilterChanged(filterActionObject) {
        for (const button of props.buttons) {
            if (filterActionObject[button.id] !== undefined) {
                button.active = filterActionObject[button.id] ? ACTIVE.TRUE : ACTIVE.FALSE;
            }
        }

        if (
            props.buttons?.length > 1 &&
            props.buttons?.every((button) => button.active) &&
            !props.buttons?.some((button) => button.additionalFilterOptions?.disableFilterDBOptimization)
        ) {
            for (const button of props.buttons) {
                button.active = ACTIVE.FAKE_TRUE;
            }
        }

        props.notifyChange();
    }

    return (
        <CombinedFilter
            items={combinedFilterItems}
            itemTitles={combinedFilterTitles}
            onChange={handleCombinedFilterChanged}
            value={value}
        />
    );
}

export default CustomCombinedFilter;
