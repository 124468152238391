import { useState, useEffect, Fragment } from 'react';
import i18n from 'Services/i18n';

import Progress from 'Bricks/Progress';
import Tooltip from 'uilib-wrappers/tooltip';

import WebConsoleUserSelect from 'Bricks/web-console-user-select';
import FormGroup from 'uilib-wrappers/form-group';
import Radio from '@uilib/business-components/Radio';

import Textarea from '@uilib/business-components/Textarea/Textarea';
import Button from 'uilib-wrappers/button';
import Modal from 'uilib-wrappers/modal';
import Spacer from '@uilib/core/Spacer/Spacer';
import Select from 'uilib-wrappers/select';
import Filters from 'Services/Filters';
import summarize from './summarize';
import InfoTooltip from 'Bricks/info-tooltip';
import colors from 'eset-ui-colors';

/** It is tricky:
 *  there is a special user 'SET Inspect' passed by serverinfo with zero id
 *  - in other views it is author.
 *
 *  but in case of assignee it is an empty user for historical reasons.
 * */
const EMPTY_ASSIGNEE_UUID = '00000000-0000-0000-0000-000000000000';

const ASSIGNEE_MODAL_ID = 'eid-assignee-modal';
const INCIDENT_STATUS = window.serverInfo.constants.incidentStatus;
const STATUS_REASON = window.serverInfo.constants.incidentStatusReason;
const MAX_COMMENTS_LENGTH = window.serverInfo.dbColumnsLength.comments.commentValue;

const STATUS_LIST = [
    { id: INCIDENT_STATUS.open, value: INCIDENT_STATUS.open, label: Filters.incidentStatusLabel[INCIDENT_STATUS.open] },
    {
        id: INCIDENT_STATUS.inProgress,
        value: INCIDENT_STATUS.inProgress,
        label: Filters.incidentStatusLabel[INCIDENT_STATUS.inProgress],
    },
    {
        id: INCIDENT_STATUS.closed,
        value: INCIDENT_STATUS.closed,
        label: Filters.incidentStatusLabel[INCIDENT_STATUS.closed],
    },
];

const DEFAULT_STATUS_MULTISELECTION = STATUS_LIST[0];
const DEFAULT_STATUS = null;
const ALLOW_EMPTY_STATUS = false;

const STATUS_REASONS_WITH_I18N = new Map([
    [STATUS_REASON.truePositive, { label: 'CLOSE_REASON_TRUE_POSITIVE' }],
    [STATUS_REASON.suspicious, { label: 'CLOSE_REASON_SUSPICIOUS', tooltip: 'CLOSE_REASON_SUSPICIOUS_TOOLTIP' }],
    [STATUS_REASON.falsePositive, { label: 'CLOSE_REASON_FALSE_POSITIVE' }],
]);

const STATUS_REASON_LIST = [STATUS_REASON.truePositive, STATUS_REASON.suspicious, STATUS_REASON.falsePositive];

const DEFAULT_EMPTY_COMMENT = '';

const DEFAULT_STATUS_REASON = STATUS_REASON_LIST[0];

function getTranslationForStatusReason(reason) {
    if (!STATUS_REASONS_WITH_I18N.has(reason)) {
        return { label: Filters.translate('incidentStatusReason', reason) };
    }
    return STATUS_REASONS_WITH_I18N.get(reason);
}

function StatusAssigneeModal(props) {
    const [defaultAssignee, setDefaultAssignee] = useState(null);
    const [assignee, setAssignee] = useState(null);

    const [defaultStatus, setDefaultStatus] = useState(DEFAULT_STATUS);
    const [status, setStatus] = useState(DEFAULT_STATUS);

    const [defaultReason, setDefaultReason] = useState(null);
    const [reason, setReason] = useState(DEFAULT_STATUS_REASON);

    const [comment, setComment] = useState(DEFAULT_EMPTY_COMMENT);

    const [assigneeError, setAssigneeError] = useState(null);
    const [statusError, setStatusError] = useState(null);

    const [menuPortal, setMenuPortal] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    useEffect(() => {
        if (props.show) {
            validate(assignee, status);
        }
    }, [assignee, status, props.show]);

    function onStatusChange(status) {
        setStatus(status);
    }

    function onAssigneeChange(assignee) {
        setAssignee(assignee);
    }

    function onReasonChange(reason) {
        setReason(reason);
    }

    function validate(assignee, status) {
        setStatusError(!status ? 'STATUS_IS_REQUIRED' : null);
        setAssigneeError(
            status?.value === INCIDENT_STATUS.inProgress && !assignee ? 'ASSIGNEE_IS_REQUIRED_FOR_IN_PROGRESS' : null
        );
    }

    function isSaveDisabled() {
        switch (status?.value) {
            case INCIDENT_STATUS.open:
                return (
                    defaultStatus?.value === status.value &&
                    (defaultAssignee?.value === assignee?.value || !assignee?.value) &&
                    !comment
                );
            case INCIDENT_STATUS.inProgress:
                return (
                    Boolean(assigneeError) ||
                    (defaultStatus?.value === status.value && defaultAssignee?.value === assignee?.value)
                );
            case INCIDENT_STATUS.closed:
                return defaultStatus?.value === status.value && defaultReason === reason && !comment;
            default:
                return true;
        }
    }

    function handleCommentChange(comment) {
        setComment(comment);
    }

    function handleSave() {
        if (isLoading) {
            return;
        }
        if (status?.value === INCIDENT_STATUS.open) {
            setIsLoading(true);
            const newComment = comment?.trim();
            props
                .changeStatus(status.value, {
                    ...(assignee?.value && { assignee: assignee.value }),
                    ...(newComment && { comment: newComment }),
                })
                .then((statusSuccess) => {
                    summarize({
                        ...(statusSuccess !== null && {
                            status: { success: statusSuccess, value: status.value },
                            ...(assignee?.value && { assignee: { success: statusSuccess, value: assignee.value } }),
                            ...(newComment && { comment: { success: statusSuccess, value: newComment } }),
                        }),
                    });
                    props.close();
                    props.reload();
                });
        } else if (status?.value === INCIDENT_STATUS.inProgress) {
            setIsLoading(true);
            props.changeStatus(status.value, { assignee: assignee.value }).then((statusSuccess) => {
                summarize({
                    ...(statusSuccess !== null && {
                        status: { success: statusSuccess, value: status.value },
                        assignee: { success: statusSuccess, value: assignee.value },
                    }),
                });
                props.close();
                props.reload();
            });
        } else if (status?.value === INCIDENT_STATUS.closed) {
            const newComment = comment?.trim();
            setIsLoading(true);
            props
                .changeStatus(status.value, { reason: reason, ...(newComment && { comment: newComment }) })
                .then((statusSuccess) => {
                    summarize({
                        ...(statusSuccess !== null && {
                            status: { success: statusSuccess, value: status.value },
                            ...(newComment && { comment: { success: statusSuccess, value: newComment } }),
                        }),
                    });
                    props.close();
                    props.reload();
                });
        }
    }

    useEffect(() => {
        const firstEntity = props.entities?.[0];

        const assigneeData = {
            label: firstEntity?.assignee?.name || firstEntity?.assigneeName,
            value: firstEntity?.assignee?.uuid || firstEntity?.assigneeUuid,
        };

        if (props.entities?.length === 1 && firstEntity) {
            const newAssignee = assigneeData.value && assigneeData.value !== EMPTY_ASSIGNEE_UUID ? assigneeData : null;
            setDefaultAssignee(newAssignee);
            onAssigneeChange(newAssignee);

            const newStatus = STATUS_LIST.find((s) => s.value === firstEntity.status);
            setDefaultStatus(newStatus);
            onStatusChange(newStatus);

            const newReason = STATUS_REASON_LIST.includes(firstEntity.statusReason)
                ? firstEntity.statusReason
                : DEFAULT_STATUS_REASON;
            setDefaultReason(firstEntity.statusReason);
            onReasonChange(newReason);

            setComment(DEFAULT_EMPTY_COMMENT);
        } else {
            setAssigneeError(null);
            setStatusError(null);

            setDefaultAssignee(null);
            setAssignee(null);

            setDefaultStatus(null);
            setStatus(DEFAULT_STATUS_MULTISELECTION);

            setDefaultReason(null);
            setReason(DEFAULT_STATUS_REASON);

            setComment(DEFAULT_EMPTY_COMMENT);
        }
    }, [props.entities]);

    useEffect(() => {
        if (props.show) {
            setMenuPortal(document.getElementById(ASSIGNEE_MODAL_ID));
            setIsLoading(false);
        }
    }, [props.show]);

    const saveDisabled = isSaveDisabled();

    return (
        <Modal
            id={ASSIGNEE_MODAL_ID}
            show={props.show}
            title="CHANGE_STATUS_AND_ASSIGNEE"
            onDispose={props.close}
            buttons={[
                <Tooltip
                    id="eid-status-assignee-button-save-container"
                    key={`eid-status-assignee-button-save-container-${saveDisabled || isLoading}`}
                    tooltipContent={saveDisabled ? statusError || assigneeError || 'NOTHING_CHANGED' : ''}
                    disabled={!saveDisabled}
                >
                    <div>
                        <Button
                            id="eid-status-assignee-button-save"
                            type="primary"
                            text="SAVE"
                            onClick={handleSave}
                            disabled={saveDisabled || isLoading}
                        />
                    </div>
                </Tooltip>,
                <Button id="eid-status-assignee-button-cancel" type="secondary" text="CANCEL" onClick={props.close} />,
            ]}
        >
            <Spacer type="p-2">
                <FormGroup
                    labelText="STATUS"
                    hasError={Boolean(statusError)}
                    validationText={statusError}
                    content={
                        <Select
                            menuPortalTarget={menuPortal}
                            allowEmpty={ALLOW_EMPTY_STATUS}
                            defaultValue={DEFAULT_STATUS}
                            options={STATUS_LIST}
                            value={status}
                            onChange={onStatusChange}
                            disabled={props.disabled}
                        />
                    }
                />
            </Spacer>
            {status?.value !== INCIDENT_STATUS.closed && (
                <Spacer type="p-2">
                    <FormGroup
                        labelText="ASSIGNEE"
                        required={status?.value === INCIDENT_STATUS.inProgress}
                        hasError={Boolean(assigneeError)}
                        validationText={assigneeError}
                        content={
                            <WebConsoleUserSelect
                                withAssignToMe
                                placeholder={
                                    status?.value === INCIDENT_STATUS.open ? 'KEEP_CURRENT_ASSIGN' : 'NO_ASSIGNEE'
                                }
                                allowEmpty={true}
                                modalId={ASSIGNEE_MODAL_ID}
                                selectedUser={assignee}
                                onChange={onAssigneeChange}
                            />
                        }
                    />
                </Spacer>
            )}
            {(status?.value === INCIDENT_STATUS.closed ||
                (props.isStatusAssigneeModalUniversal && status?.value === INCIDENT_STATUS.open)) && (
                <Fragment>
                    {status?.value === INCIDENT_STATUS.closed && (
                        <Spacer type="p-2">
                            <FormGroup
                                id="reason-list-form"
                                labelText="SELECT_RESOLUTION_FOR_CLOSING"
                                content={
                                    <Radio name="reason-list" onChange={onReasonChange} value={reason}>
                                        {STATUS_REASON_LIST.map((reason) => {
                                            const reasonTranslation = getTranslationForStatusReason(reason);
                                            return (
                                                <Radio.Item value={reason} key={reason}>
                                                    {i18n(reasonTranslation.label)}
                                                    {reasonTranslation.tooltip && (
                                                        <InfoTooltip
                                                            iconColor={colors.primary}
                                                            placement="right"
                                                            tooltipContent={
                                                                <span
                                                                    style={{
                                                                        width: '300px',
                                                                        display: 'block',
                                                                        'word-wrap': 'break-word',
                                                                    }}
                                                                >
                                                                    {i18n(reasonTranslation.tooltip)}
                                                                </span>
                                                            }
                                                        />
                                                    )}
                                                </Radio.Item>
                                            );
                                        })}
                                    </Radio>
                                }
                            />
                        </Spacer>
                    )}
                    <Spacer type="p-2">
                        <FormGroup
                            labelText="COMMENT"
                            optionalText="OPTIONAL"
                            content={
                                <Textarea
                                    value={comment}
                                    onChange={handleCommentChange}
                                    maxHeight={250}
                                    maxLength={MAX_COMMENTS_LENGTH}
                                    placeholder={i18n('ENTER_OPTIONAL_COMMENT_HERE_MAX_CHARACTERS', {
                                        count: MAX_COMMENTS_LENGTH,
                                    })}
                                />
                            }
                        />
                    </Spacer>
                </Fragment>
            )}
            <Progress showProgress={isLoading} />
        </Modal>
    );
}

export default StatusAssigneeModal;
